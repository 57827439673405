import { Avatar } from "antd"
import { UnityButton } from "libs/UnityCoreReact"
import React from "react"
import { connect } from "react-redux"

import { navigate } from "actions/navigate"
import { colors } from "assets/stylesheets/common"
import {ROLE_ADMIN, ROLE_SUPERADMIN} from "constants/admin"
import { A1_ACCOUNT_LINKING_ENABLED } from "constants/featureFlags"
import {ADMIN, LOGOUT, SPACE_PICKER} from "constants/routes"

import UserPopoverContentAltairOneStatus from "./UserPopoverContentAltairOneStatus/UserPopoverContentAltairOneStatus"
import UserPopoverContentSettingsAccount from "./UserPopoverContentSettingsAccount"


const mapStateToProps = (state) => ({
  username: state.authentication.userInfo.username,
  email: state.authentication.userInfo.email,
  swxRole: state.authentication.userInfo.swxrole,
  pathname: state.router.location.pathname
})


const hasAdminRole = (swxRole) =>{
  return swxRole === ROLE_SUPERADMIN || swxRole === ROLE_ADMIN
}


const UserPopoverContent = ({
  username = "",
  email = "",
  isLoading = false,
  swxRole= "",
  navigate,
  pathname = ""
}) => {
  return (
    <div style={styles.wrapper}>
      <div style={styles.avatarAndUserInfoContainer}>
        <Avatar
          size={32}
          style={styles.avatar}
        >
          {username[0].toUpperCase()}
        </Avatar>
        <div style={styles.userNameAndEmailContainer}>
          <span style={styles.userNameText}>{username}</span>
          <span style={styles.emailText}>{email}</span>
          {hasAdminRole(swxRole) && <span style={styles.emailText}>{swxRole}</span>
          }
        </div>
      </div>
      {username.startsWith("a1:") ? null : (
        <UserPopoverContentSettingsAccount isLoading={isLoading}/>
      )}
      { A1_ACCOUNT_LINKING_ENABLED && <UserPopoverContentAltairOneStatus isLoading={isLoading}/> }
      <div style={styles.buttonContainer}>
        {hasAdminRole(swxRole) && <UnityButton
          id="admin-view-button"
          label={ pathname.startsWith("/admin") ? "Exit Admin view" : "Go to Admin view"}
          type="primary"
          onClick={() => navigate({to: pathname.startsWith("/admin") ? SPACE_PICKER : ADMIN })}
        />
        }
        <UnityButton
          id="logout-button"
          label="Logout"
          leftIcon="power-settings-new"
          type="primary"
          onClick={() => navigate({to: LOGOUT})}
        />
      </div>
    </div>
  )
}

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "end",
  },
  buttonContainer: {
    display: "flex",
    gap: "10px",
  },
  avatarAndUserInfoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    width: "100%",
  },
  userNameAndEmailContainer: {
    display: "flex",
    flexDirection: "column",
  },
  userNameText: {
    color: colors.black,
  },
  emailText: {
    color: colors.text2,
  },
  avatar: {
    backgroundColor: colors.deepBlue,
    verticalAlign: "middle",
  }
}

export default connect(mapStateToProps, {navigate})(UserPopoverContent)
