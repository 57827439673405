import { Alert } from "antd"
import React from "react"

import { colors } from "assets/stylesheets/common"


function BannerHeader() {
  return (
    <Alert
      type="error"
      showIcon={false}
      message={<div style={styles.bannerText}>
        Upcoming API Updates: Changes take effect on January 27, 2025 – Review the details <a style={styles.bannerLink}
          target="_blank"
          rel="noopener noreferrer"
          href="https://help.altair.com/altair-iot-studio/topics/api/new_api_version.htm">here</a> to
        avoid disruptions.
      </div>}
      closable
      // banner
      // icon={
      //   <unity-icon icon={"unity:info_circle"}></unity-icon>
      // }
    />
  )
}

export default BannerHeader

const styles = {
  bannerText: {
    fontWeight: "600",
    color: colors.white
  },
  bannerLink: {
    color: "white",
    textDecoration: "underline"
  },
}