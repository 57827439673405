/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import {
  createAppRequest,
  deleteAppRequest,
  getAppRequest,
  getAppsRequest,
  updateAppRequest
} from "utils/apps"
import { makeLib } from "utils/misc"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS
} from "utils/notifications"
import { getPoliciesRequest } from "utils/policies"
import { deleteMultipleRoles, getRolesRequest } from "utils/roles"

import { addRoles, getRolePolicies } from "./roles"
import { makeActions } from "./utiliducks"

/* == ACTIONS === */
const actionList = [
  "setAppsAction",
  "setAppAction",
  "setAppPoliciesAction",
  "setAppRolesAction",
  "addAppAction",
  "updateAppAction",
  "removeAppsAction",
  "setPagingAction"
]
const {
  setAppsAction,
  setAppAction,
  setAppPoliciesAction,
  setAppRolesAction,
  addAppAction,
  updateAppAction,
  removeAppsAction,
  setPagingAction
} = makeActions("apps", actionList)

/* === INITIAL STATE === */
const initialState = {
  apps: [],
  appsLib: {},
  appPolicies: {},
  appRoles: {},
  paging: { previous_cursor: "", next_cursor: "" },
  currentApp: undefined
}

/* === Reducer === */
export default createReducer(initialState, {
  [setAppsAction]: (state, { payload: { apps }}={}) => ({
    ...state,
    apps,
    appsLib: makeLib({data: apps})
  }),
  [setAppAction]: (state, { payload: { app }}={}) => ({
    ...state,
    currentApp: app
  }),
  [addAppAction]: (state, { payload: { app }}) => ({
    ...state,
    apps: [
      ...state.apps,
      app
    ],
    appsLib: {
      ...state.appsLib,
      [app.id]: app
    }
  }),
  [updateAppAction]: (state, { payload: { id, app: updatedApp }}) => {
    const currentApp = id === state.currentApp?.id
      ? {...state.currentApp, ...updatedApp}
      : state.currentApp
    return {
      ...state,
      apps: state.apps.map(app => app.id === id? updatedApp : app),
      appsLib: {
        ...state.appsLib,
        [id]: {
          ...state.appsLib[id], ...updatedApp
        }
      },
      currentApp
    }
  },
  [removeAppsAction]: (state, { payload: { ids }}) => {
    const apps = state.apps.filter(app => !ids.includes(app.id))
    return (
      {
        ...state,
        apps,
        appsLib: makeLib({data: apps})
      })
  },
  [setAppPoliciesAction]:  (state, { payload: { appId, policies }}={}) => {
    return ({
      ...state,
      appPolicies: {
        ...state.appPolicies,
        [appId]: policies
      }
    })},
  [setAppRolesAction] : (state, { payload: { appId, roles }}={}) => ({
    ...state,
    appRoles: {
      ...state.appRoles,
      [appId]: roles
    }
  }),
  [CLEAN_SPACE]: () => initialState,
  [setPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    paging
  })
})


/* === DISPATCHERS === */
export const getUserApps = () => {
  return async dispatch => {
    try {
      const response = await getAppsRequest()
      const { data, paging } = response
      const apps = data ? data : response // preserve backwards compatibility
      dispatch(setAppsAction({apps}))
      if (paging) dispatch(setPaging({paging}))
      return apps
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Apps could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getNextApps = () => {
  return async (dispatch, getState) => {
    const {
      apps: {
        apps,
        paging: oldPaging
      }
    } = getState()
    const { data, paging } = await getAppsRequest({next_cursor: oldPaging.next_cursor})
    dispatch(setPaging({paging}))
    dispatch(setAppsAction({ apps: [...apps, ...data]}))
  }
}

export const getApp = (id) => {
  return async dispatch => {
    try {
      if (!id) {
        dispatch(setAppAction({id, app: undefined}))
        return
      }
      const response = await getAppRequest(id)
      const { data } = response
      const app = data? data : response // preserve backwards compatibility
      dispatch(setAppAction({app}))
      return app
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "App could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const clearCurrentApp = () => {
  return dispatch => {
    dispatch(setAppAction({app: undefined}))
  }
}

export const createApp = (app) => {
  return async dispatch => {
    try {
      const newApp = await createAppRequest(app)
      dispatch(addAppAction({app: newApp}))
      return newApp
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "App could not be created",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const updateApp = (id, app) => {
  return async dispatch => {
    try {
      const updatedApp = await updateAppRequest(id, app)
      return dispatch(updateAppAction({id, app: updatedApp}))
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "App could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const deleteApps = (ids) => {
  return async dispatch => {
    try {
      // TODO: we might need to pace request as we are doing with Things
      const responses = ids.map(async name => {
        return deleteAppRequest(name)
      })
      await Promise.all(responses)
      dispatch(removeAppsAction({ids}))

      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Successful",
        subtext: `Successfully deleted ${ids.length} app${ids.length !== 1 ? "s" : ""}`,
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })

      return true
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${ids.length===1? "App": "Some apps"} could not be deleted`,
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}

export const getAppPolicies = app => {
  return async (dispatch, getState) => {
    try {
      const { id, client_id } = app
      if (!id) {
        dispatch(setAppPolicies(id, {}))
        return
      }
      const { apps: { appRoles: { [id]: roles } = {} } = {} } = getState()

      // get app role policies
      let rolePolicies = []
      for (const r of roles) {
        rolePolicies = [...rolePolicies, ...await dispatch(getRolePolicies(r.role))]
      }

      // get app policies
      const { data: appPolicies } = await getPoliciesRequest({subject: client_id })

      const policies = [...rolePolicies, ...appPolicies]
      dispatch(setAppPolicies(id, policies))
      return policies
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Could not get list of app policies",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

// export const getRolePoliciesApp = (app, selectedRoles=[]) => { not needed?
//   return async (dispatch) => {
//     try {
//       if (app?.client_id){
//         const { id, client_id } = app

//         // get app policies
//         const { data: appPolicies } = await getPoliciesRequest({subject: client_id })
//         // get app role policies
//         const rolePolicies = (await Promise.all(selectedRoles.map(r => dispatch(getRolePolicies(r))))).flat() || []

//         const policies = [...rolePolicies, ...appPolicies]
//         dispatch(setAppPolicies(id, policies))
//         return policies
//       } else {
//         // get app role policies
//         const rolePolicies = (await Promise.all(selectedRoles.map(r => dispatch(getRolePolicies(r))))).flat() || []
//         dispatch(setAppPolicies("new", rolePolicies))
//         return rolePolicies
//       }
//     }
//     catch(error) {
//       console.error(`${error.name}: ${error.message}`)
//       addMessage({
//         target: GLOBAL_NOTIFICATIONS,
//         text: "Could not get policies for selected roles",
//         subtext: error.message,
//         type: MESSAGE_TYPE_ERROR
//       })
//     }
//   }
// }

export const getAppRoles = app => {
  return async dispatch => {
    try {
      const { id, client_id } = app
      if (!id) {
        dispatch(setAppRolesAction({ appId: id, roles: [] }))
        return
      }
      const { data: roles } = await getRolesRequest({ subject: client_id, limit: 1000 })
      dispatch(setAppRolesAction({ appId: id, roles }))
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Could not get list of app roles",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const updateAppRoles = (app, newRoles, showNotification=true) => {
  return async (dispatch, getState) => {
    const { id: appId, client_id: appClient } = app
    const { apps: { appRoles: { [appId]: appRoles } = {} } = {} } = getState()
    const appRolesArray = appRoles?.map(r => r.role) || []

    // find added and removed roles
    const add = newRoles?.filter(r => !appRolesArray?.includes(r))
    const remove = appRolesArray?.filter(r => !newRoles?.includes(r))

    // call api to update roles
    let updatedRoles = [...(appRoles || [])]
    let addedRoles = []
    let haveRequestsFailed = false

    if (add.length) {
      addedRoles = await dispatch(addRoles(add.map(role => ({ role, subject: appClient }))))
      updatedRoles = [...updatedRoles, ...addedRoles]
      if (addedRoles?.length !== add.length) haveRequestsFailed = true
    }

    if (remove.length) {
      // only remove if add was successful
      if (!haveRequestsFailed) {
        const rolesToRemove = remove.map(role => appRoles?.find(r => r.role === role).id) || []
        const { has_errors, results } = await deleteMultipleRoles(rolesToRemove)
        const successful = []
        results.forEach((r, index) => { if (!has_errors.includes(index)) successful.push(rolesToRemove[index])})
        updatedRoles = appRoles?.filter(r => !successful.includes(r.id)) || []
        if (has_errors.length) haveRequestsFailed = true
      }
    }

    if (showNotification){
      if (!haveRequestsFailed) {
        addMessage({
          text: "App roles updated successfully",
          type: MESSAGE_TYPE_SUCCESS
        })
      }
      else {
        addMessage({
          text: "App roles could not be updated",
          type: MESSAGE_TYPE_ERROR
        })
      }
    }
    dispatch(setAppRolesAction({ appId, roles: updatedRoles }))
  }
}

export const setAppPolicies = (appId, policies) => {
  return dispatch => {
    dispatch(setAppPoliciesAction({appId, policies}))
  }
}

/* === UTILS === */

export const setPaging = ( {paging} ) => {
  return dispatch => dispatch(setPagingAction({paging}))
}
