import { UnityTypography } from "libs/UnityCoreReact"
import React, {useEffect, useState} from "react"

import { UPDATE_SELECTED_MQTT_TOPIC } from "utils/utilityBelt"


const MqttInspectorTopicSelection = () => {
  const [, setSelectedTopic] = useState("")
  useEffect(() => {
    document.addEventListener(UPDATE_SELECTED_MQTT_TOPIC, updateSelectedTopic)

    return function cleanup() {
      document.removeEventListener(UPDATE_SELECTED_MQTT_TOPIC, updateSelectedTopic)
    }
  })

  const updateSelectedTopic = ({detail: {topic=""}={}}) => {
    setSelectedTopic(topic)
  }

  return (
    <>
      <UnityTypography key="mqtt-topics-title">MQTT Inspector</UnityTypography>
    </>
  )
}

export default MqttInspectorTopicSelection