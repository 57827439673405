/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { cleanSpace } from "actions/auth"
import { makeLib } from "utils/misc"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS,
} from "utils/notifications"
import {
  createSpaceRequest,
  deleteSpaceRequest,
  getSpacesRequest,
  leaveSpaceRequest,
  updateSpaceRequest
} from "utils/spaces"
import {
  clearSpace,
  clearSpaceCategory,
  getSpace} from "utils/storage"

import { makeActions } from "./utiliducks"


/* == ACTIONS === */
const actionList = [
  "setSpacesAction",
  "addSpaceAction",
  "updateSpaceAction",
  "removeSpaceAction",
  "setPagingAction"
]
const {
  setSpacesAction,
  addSpaceAction,
  updateSpaceAction,
  removeSpaceAction,
  setPagingAction
} = makeActions("spaces", actionList)

/* === INITIAL STATE === */
const initialState = {
  spaces: [],
  spacesLib: {},
  paging: {
    previous_cursor: "",
    next_cursor: ""
  },
}

/* === Reducer === */
export default createReducer(initialState, {
  [setSpacesAction]: (state, { payload: { spaces }}={}) => ({
    ...state,
    spaces,
    spacesLib: makeLib({data: spaces})
  }),
  [addSpaceAction]: (state, { payload: { space }}={}) => ({
    ...state,
    spaces: [
      ...state.spaces,
      space
    ],
    spacesLib: {
      ...state.spacesLib,
      [space.id]: space
    }
  }),
  [updateSpaceAction]: (state, { payload: { space: updatedSpace }}={}) => {
    const { id } = updatedSpace
    return {
      ...state,
      spaces: state.spaces.map(space => space.id === id? updatedSpace : space),
      spacesLib: {
        ...state.spacesLib,
        [id]: {
          ...state.spacesLib[id], ...updatedSpace
        }
      }
    }
  },
  [removeSpaceAction]: (state, { payload: { id }}={}) => {
    const spaces = state.spaces.filter(space => id !== space.id)
    return {
      ...state,
      spaces,
      spacesLib: makeLib({data: spaces})
    }
  },
  [setPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    paging
  }),
})


/* === DISPATCHERS === */
export const getSpaces = () => {
  return async dispatch => {
    try {
      const {data, paging } = await getSpacesRequest()
      dispatch(setSpacesAction({spaces: data}))
      if (paging) dispatch(setPagingAction({paging}))
      return data
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Spaces could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const createSpace = ({id, name, color, visibility}) => {
  return async dispatch => {
    const body = {
      id,
      name,
      color,
      settings: [
        {
          name: "menu",
          options: {
            visibility
          }
        }
      ]
    }
    const space = await createSpaceRequest(body)
    dispatch(addSpaceAction({space}))

    addMessage({
      target: GLOBAL_NOTIFICATIONS,
      text: "Space Created",
      subtext: `${name} space was created successfully.`,
      type: MESSAGE_TYPE_SUCCESS,
      timeout: 4000
    })
    return space
  }
}

export function updateSpace({
  id,
  name,
  color,
  allow_admin_access,
  visibility
}) {

  const body = {
    name,
    color,
    allow_admin_access,
    settings: [
      {
        name: "menu",
        options: {
          visibility
        }
      }
    ]
  }

  return async dispatch => {
    try {
      const space = await updateSpaceRequest(id, body)

      await dispatch(updateSpaceAction({space}))

      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Space Updated",
        subtext: `${name} space was updated successfully.`,
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })

      return space

    } catch(error) {
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Space could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const deleteSpace = (id) => {
  return async dispatch => {
    try {
      await deleteSpaceRequest(id)
      await dispatch(cleanSpace())

      // Remove space related items from localstorage
      clearSpaceCategory(id)
      const savedSpace = getSpace()
      if (savedSpace === id) {
        clearSpace()
      }
      await dispatch(removeSpaceAction({id}))

      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Space deleted successfully",
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })

      return true
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Space could not be deleted",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}

export const leaveSpace = (id) => {
  return async dispatch => {
    try {
      await leaveSpaceRequest(id)
      dispatch(cleanSpace())

      // Remove space related items from localstorage
      clearSpaceCategory(id)
      const savedSpace = getSpace()
      if (savedSpace === id) {
        clearSpace()
      }

      return true
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Error leaving Space",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}
