import React from 'react'

export interface paneProps {
  id: string
  header?: React.ReactNode
  body?: React.ReactNode
  footer?: React.ReactNode
}

class UnityPane extends React.Component<paneProps> {

  render() {
    const { id, header, body, footer } = this.props
    return (
      <>
       {!!header &&
        <div
          slot={`${id}::header`}
          key={`${id}-header`}
          style={{flex: 1, width: '100%', display: 'flex'}}
        >
          {header}
        </div>
      }
      <div
        slot={id}
        key={`${id}-body`}
        style={{flex: 1, width: '100%', display: 'flex'}}
      >
        {body}
      </div>
      {!!footer &&
        <div
          slot={`${id}::footer`}
          key={`${id}-footer`}
          style={{flex: 1, width: '100%', display: 'flex'}}
        >
          {footer}
        </div>
      }
      </>
    )
  }
}

export default UnityPane
