import { UnityTypography } from "libs/UnityCoreReact"
import { noop } from "lodash"
import React from "react"


function Dialog({
  body,
  bottom,
  cancelOnOutsideClick, // bool to control if clicking outside of dialog will call toggle to close
  dialogTitle, // title shown on top
  subtitle, //subtitle displayed below the title
  id,
  modalTitle, // same as dialogTitle, for backwards compatibility
  noScroll, // maybe we can deprecate this
  show, // bool to control if modal is open or close
  title, // same as dialogTitle; we should deprecate this use after udpating our modal instances
  toggle, // callback that controls modals open/close
  top, // top-right content
  style,
  bodyStyle
}) {

  const handleClickOutside = () => toggle(false)

  let bodyStyleMerged = { ...styles.modalBody }
  if (noScroll) bodyStyleMerged = { ...bodyStyleMerged, ...styles.noScroll }
  if (bodyStyle) bodyStyleMerged = { ...bodyStyleMerged, ...bodyStyle }

  if (!show) return null

  return (
    <div style={{...styles.dialogWrapper, ...style}}>
      <div
        id={`${id}-dialog-backdrop`}
        onClick={cancelOnOutsideClick ? handleClickOutside : noop}
        style={styles.backdrop}
      />
      <dialog id={id} style={styles.dialog} open={show}>
        <div style={styles.modalTitle}>
          <div style={{display: "flex", flexDirection: "column"}}>
            <UnityTypography size="header2">
              {dialogTitle || modalTitle || title}
            </UnityTypography>
            {subtitle &&
                <UnityTypography style={{fontSize: "small"}}>
                  {subtitle}
                </UnityTypography>
            }
          </div>
          {top &&
              <div style={styles.buttonBox}>
                <div style={styles.buttons}>
                  {top}
                </div>
              </div>
          }
        </div>
        {body &&
            <div style={bodyStyleMerged}>
              {body}
            </div>
        }
        { bottom &&
          <div style={styles.modalButtonsBox}>
            {bottom}
          </div>
        }
      </dialog>
    </div>
  )
}

const styles = {
  backdrop: {
    position: "fixed",
    inset: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: -1,
    backgroundColor: "var(--modal-backdrop-color, var(--default-modal-backdrop-color))"
  },
  buttonBox: {
    flex: 1,
    width: "auto",
    display: "flex",
    flexDirection: "row-reverse"
  },
  buttons: {
    flex: 0,
    display: "flex",
    flexDirection: "row",
    padding: 12,
    boxSizing: "border-box"
  },
  dialog: {
    padding: 0,
    zIndex: 20,
    minWidth: 300,
    boxShadow: "var(--modal-box-shadow, var(--default-modal-box-shadow))",
    border: "none",
    backgroundColor: "var(--modal-background-color, var(--default-modal-background-color))"
  },
  dialogWrapper: {
    alignItems: "center",
    display: "flex",
    inset: 0,
    justifyContent: "center",
    position: "absolute",
    zIndex: 1000,
    "--default-modal-backdrop-color": "transparent",
    "--default-modal-background-color": "var(--background-color, white)",
    "--default-title-border-color": "var(--light-grey-text-color, #b8b8b8)",
    "--default-modal-box-shadow": "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
  },
  modalTitle: {
    display: "flex",
    borderBottom: "1px solid var(--title-border-color, var(--default-title-border-color))",
    marginTop: 0,
    padding: "0px 12px",
    height: 50,
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center"
  },
  modalBody: {
    padding: 12,
    borderBottom: "1px solid var(--title-border-color, var(--default-title-border-color))",
    maxHeight: "600px",
    overflow: "auto"
  },
  modalButtonsBox: {
    padding: 12,
    display: "flex",
    justifyContent: "flex-end"
  },
  noScroll: {
    overflow: "visible"
  }
}

export default Dialog
