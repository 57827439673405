import {
  UnityButton,
  UnitySearchBar
} from "libs/UnityCoreReact"
import React, { useState } from "react"

import {colors} from "assets/stylesheets/common"
import {
  refreshFunctionLog,
  toggleFunctionLogSearchFilter,
  updateFunctionLogSearch
} from "utils/utilityBelt"


const FunctionLogsSearch = ({functionName}) => {
  const [searchText, setSearchText] = useState("")
  const [filterLogs, setFilterLogs] = useState(false)
  //This value is displayed in lazy log searchbar. Not sure it is useful
  // const [matchCount, setMatchCount] = useState(0)

  const handleRefresh = () => {
    refreshFunctionLog(functionName)
  }

  const updateSearch = ({text=""}={}) => {
    setSearchText(text)
    updateFunctionLogSearch(functionName, text)
  }

  const toggleFilter = () => {
    const nextFilter = !filterLogs
    setFilterLogs(nextFilter)
    toggleFunctionLogSearchFilter(functionName, nextFilter)
  }

  return (
    <>
      <UnitySearchBar
        key={"search-bar"}
        search={searchText}
        onChange={updateSearch}
        debounceTime={500}
        onClear
        style={styles.searchBar}
      />
      <UnityButton
        key="search-filter-button"
        centerIcon={"unity:text_justify_left"}
        type='borderless'
        onClick={toggleFilter}
        style={filterLogs ? styles.activeFilter : {}}
      />
      <UnityButton
        key="refresh-button"
        centerIcon={"unity:refresh"}
        type='borderless'
        onClick={handleRefresh}
      />
    </>
  )
}

const styles = {
  searchBar: {
    "--input-border-radius": "30px",
    backgroundColor: "#FFF",
    margin: "2px 0"
  },
  activeFilter: {
    "--button-borderless-text-color": colors.primary1
  }
}

export default FunctionLogsSearch