import { UnityTextInput } from "libs/UnityCoreReact"
import React, { Component } from "react"

import {
  inputStyle,
  overrideDirtyColor
} from "./InputStyles"

class TextInput extends Component {

  catchReturn = (ev) => {
    const { submit, noBlur=false } = this.props
    if (ev.key === "Enter") {
      !noBlur && ev.target.blur()
      if (submit instanceof Function) submit()
    }
  }

  render() {
    const {
      input,
      input: {
        value: inputValue,
        onChange: inputOnChange=()=>{}
      }={},
      type,
      onChange=()=>{},
      value,
      meta: {
        pristine=true,
        touched=false,
        valid=false,
        error=""
      }={},
      trackDirty,
      hideErrorOnPristine=false,
      showErrorOnUntouched=false,
      hideError,
      maxLength,
      style: givenStyle,
      innerRightIcon: originalRightIcon,
      inlineValidIcons=false,
      ...otherProps
    } = this.props

    const showError = hideError ? false : hideErrorOnPristine ? !pristine : showErrorOnUntouched ? true : touched

    const valueToUse = value ?? inputValue
    const stringifiedValue = !!valueToUse && typeof valueToUse === "object" ? JSON.stringify(valueToUse) : valueToUse

    let innerRightIcon = originalRightIcon, style = {...givenStyle}

    if (inlineValidIcons && showError) {
      innerRightIcon = valid ? "unity:circle_check" : "unity:error_circle"
      style["--inner-icon-size"] = "20px"
      style["--inner-icon-color"] = error ? "var(--tertiary-1-color)" : "var(--tertiary-3-color)"
    }

    return (
      <UnityTextInput
        {...otherProps}
        {...input}
        onChange={(...args) => {
          onChange(...args)
          inputOnChange(...args)
        }}
        value={stringifiedValue}
        style={overrideDirtyColor({
          trackDirty,
          error,
          pristine,
          styles: {
            ...inputStyle,
            ...style
          }
        })}
        time={type === "time"}
        error={showError && error}
        onKeyDown={this.catchReturn}
        dirty={!!trackDirty && !pristine}
        innerRightIcon={innerRightIcon}
        maxlength={maxLength}
      />
    )
  }
}

export default TextInput

// const styles = {}
