import React, { Component } from 'react'
// import '@bit/smartworks.unity.unity-core/unity-multi-pane'
import UnityPane, { paneProps } from '../UnityPane/UnityPane.tsx'

export default class UnityMultiPane extends Component<MultiPaneProps> {

  private multiPaneRef = React.createRef<MultiPaneProps>()

  componentDidMount = () => {
    this.updateProperties()
  }

  componentDidUpdate = () => {
    this.updateProperties()
  }

  updateProperties = () => {
    const { props, multiPaneRef } = this
    const unityMultiPane = multiPaneRef.current
    const { panes, onClose, onCollapseChange, closeButton, collapseButton } = props
    if (unityMultiPane) {
      unityMultiPane.onClose = onClose || (() => {})
      unityMultiPane.onCollapseChange = onCollapseChange || (() => {})
      unityMultiPane.panes = panes
      if (closeButton) unityMultiPane.closeButton = closeButton
      if (collapseButton) unityMultiPane.collapseButton = collapseButton
    }
  }

  render() {
    const {
      children,
      panes=[],
      closeButton,
      collapseButton,
      labels,
      visiblePanes,
      collapsedPanes,
      paneWidths,
      ...otherProps
    } = this.props
    let multiPaneArgs : MultiPaneProps = {...otherProps}

    if (closeButton) multiPaneArgs.closeButton = closeButton
    if (collapseButton) multiPaneArgs.collapseButton = collapseButton

    if (children && panes) console.warn('UnityMultiPane: this instance has defined the "panes" prop, but it also has children. The content of "panes" will be ignored; only the children will be rendered.')

    return (
      <unity-multi-pane
        className="ump-wrapper"
        ref={this.multiPaneRef}
        labels={JSON.stringify(labels)}
        visiblePanes={JSON.stringify(visiblePanes)}
        collapsedPanes={JSON.stringify(collapsedPanes)}
        paneWidths={JSON.stringify(paneWidths)}
        {...multiPaneArgs}
      >
        {children? 
          children :
          panes.map((pane) => React.isValidElement(pane)? pane : <UnityPane {...pane } id={pane.key || pane.id}/> )
        }
      </unity-multi-pane>
    )
  }
}

interface paneObject extends paneProps {
  key?: string
}

export interface MultiPaneProps extends React.HTMLAttributes<HTMLElement>{
  [key:string]: any,
  panes?: (paneObject|React.ReactElement)[]
  visiblePanes?: string[]
  collapsedPanes?: string[]
  paneWidths?: object,
  label?: string
  closeButton?: boolean
  onClose?: Function
  collapsed?: boolean
  onCollapseChange?: Function
  style?: multiPaneStyleTypes
}

export type multiPaneStyleTypes = React.CSSProperties & {
  '--border-color'?: string
  '--bar-border-color'?: string
  '--background'?: string
  '--bar-background'?: string
  '--pane-border-width'?: string
  '--pane-border-color'?: string
  '--bar-width'?: string
  '--header-border'?: string
  '--collapse-button-padding'?: string
  '--pane-z-index'?: string
}
