import {
  UnityButton,
  UnityDropdown,
  UnityMultiPane,
  UnityPageHeader,
  UnityTable,
  UnityTextInput,
  UnityTypography
} from "libs/UnityCoreReact"
import React, { useCallback, useState } from "react"
import { connect } from "react-redux"

import CodeEditor from "components/common/Inputs/CodeEditor"
import { CONTENT_TYPES, METHODS, REQUEST_DETAILS } from "constants/httpInspector"
import { getSpaceId, requestClientCredentialsToken, revokeTokenRequest } from "utils/auth"
import axiosInstance from "utils/axios"
import { prettyJSON, validateJSON } from "utils/misc"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS,
} from "utils/notifications"
import { formatUriParams } from "utils/routes"
import { updateTabInBelt } from "utils/utilityBelt"


const TITLE = "API Client"
const DETAILS_TITLE = "Response"
const DETAILS = "response"
const MAIN = "request"

const methodOptions = [
  { id: METHODS.GET, label: "GET" },
  { id: METHODS.POST, label: "POST" },
  { id: METHODS.PUT, label: "PUT" },
  { id: METHODS.DELETE, label: "DELETE" },
  { id: METHODS.PATCH, label: "PATCH" }
]

const requestDetailsTabs = [
  {key: REQUEST_DETAILS.QUERY, label: "Query", id: REQUEST_DETAILS.QUERY},
  {key: REQUEST_DETAILS.HEADERS, label: "Headers", id: REQUEST_DETAILS.HEADERS},
  {key: REQUEST_DETAILS.AUTH, label: "Auth", id: REQUEST_DETAILS.AUTH},
  {key: REQUEST_DETAILS.BODY, label: "Body", id: REQUEST_DETAILS.BODY},
]
const RESPONSE = "response"
const RESPONSE_HEADERS = "response-headers"
const responseTabs = [
  {key: RESPONSE, label: "Response", id: RESPONSE},
  {key: RESPONSE_HEADERS, label: "Headers", id: RESPONSE_HEADERS},
]
const bodyTabs = [
  {key: CONTENT_TYPES.BODY_JSON, label: "Json", id: CONTENT_TYPES.BODY_JSON},
  {key: CONTENT_TYPES.BODY_TEXT, label: "Text", id: CONTENT_TYPES.BODY_TEXT},
  {key: CONTENT_TYPES.BODY_FORM, label: "Form-encoded", id: CONTENT_TYPES.BODY_FORM},
]

const HEADER_COLUMNS = [
  {
    key: "header",
    label: "Header"
  },
  {
    key: "value",
    label: "Value"
  }
]

const convertParamsListToObj = (paramsList) => {
  return paramsList.reduce((params, {param, value}) => {
    if (param) {
      if (params[param]) {
        if (Array.isArray(params[param])) {
          params[param].push(value)
        } else {
          params[param] = [params[param], value]
        }
      } else {
        params[param] = value
      }
    }
    return params
  }, {})
}

const convertHeaderListToObj = (headerList) => {
  return headerList.reduce((headers, {header, value}) => {
    if (header) {
      headers[header] = value
    }
    return headers
  }, {})
}

const stateToProps = () => ({})

const HttpInspector = ({
  tab,
  initialValues: {
    requestMethod: initialRequestMethod,
    requestUrl: initialRequestUrl,
    selectedTab: initialSelectedTab,
    requestBody: initialRequestBody
  }={}
}) => {
  const [space] = useState(() => {
    return {
      current: getSpaceId()
    }
  })

  const [requestMethod, setRequestMethod] = useState(initialRequestMethod || methodOptions[0].id)
  const [requestUrl, setRequestUrl] = useState(initialRequestUrl || `/spaces/${space.current}`)
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab || requestDetailsTabs[0].id)
  const [requestHeaders, setRequestHeaders] = useState([{}])
  const [requestUrlParams, setRequestUrlParams] = useState([{}])
  const [requestFormItems, setRequestFormItems] = useState([{}])
  const [requestBody, setRequestBody] = useState(initialRequestBody || "")
  const [requestBodyText, setRequestBodyText] = useState("")
  const [requestBodyTab, setRequestBodyTab] = useState(CONTENT_TYPES.BODY_JSON)
  const [selectedRespTab, setSelectedRespTab] = useState(responseTabs[0].id)
  const [responseHeaders, setResponseHeaders] = useState({})
  const [responseBody, setResponseBody] = useState("")
  const [responseStatus, setResponseStatus] = useState("")
  const [responseTime, setResponseTime] = useState()
  const [authClientId, setAuthClientId] = useState()
  const [authClientSecret, setAuthClientSecret] = useState()
  const [authScopes, setAuthScopes] = useState([])

  const getVisiblePanes = () => {
    // return selectedTopic
    //   ? [MAIN, DETAILS]
    //   : [MAIN]
    //TODO: updated based on response state
    return [MAIN, DETAILS]
  }
  const getPaneLabels = () => {
    return {
      [MAIN]: TITLE,
      [DETAILS]: DETAILS_TITLE
    }
  }

  const submitRequest = useCallback(async () => {
    //Build query
    let url = requestUrl
    const queryParams = convertParamsListToObj(requestUrlParams)
    let body = ""
    let headersList

    try {
      const parsedUrl = new URL(url)
      if (parsedUrl.pathname.startsWith("/boundaries/")){
        return addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "Restricted URL",
          subtext: "Requests to `/boundaries` are not allowed.",
          type: MESSAGE_TYPE_ERROR
        })
      }
    }
    catch {
      if (url.startsWith("/boundaries/") || url.startsWith("boundaries/")){
        return addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "Restricted URL",
          subtext: "Requests to `/boundaries` are not allowed.",
          type: MESSAGE_TYPE_ERROR
        })
      }
    }

    if (requestBodyTab === CONTENT_TYPES.BODY_JSON) {
      body = requestBody
      headersList = [{ header: "Content-Type", value: "application/json" }, ...requestHeaders]
    } else if (requestBodyTab === CONTENT_TYPES.BODY_TEXT) {
      body = requestBodyText
      headersList = [{ header: "Content-Type", value: "text/plain" }, ...requestHeaders]
    } else if (requestBodyTab === CONTENT_TYPES.BODY_FORM) {
      const formObj = convertParamsListToObj(requestFormItems)
      body = new URLSearchParams(formObj).toString()
      headersList = [{ header: "Content-Type", value: "application/x-www-form-urlencoded" }, ...requestHeaders]
    }
    const headers = convertHeaderListToObj(headersList)
    headers["api-inspector-request"] = "true"

    //add url query params
    if (Object.keys(queryParams).length > 0) {
      url += formatUriParams(queryParams)
    }

    setResponseStatus("Pending")
    setResponseTime("")
    const startTime = Date.now()
    try {
      const response = await axiosInstance({
        method: requestMethod,
        url,
        data: body,
        rootRequest: true,
        bypassInterceptors: true,
        headers
      })
      setResponseHeaders(response.headers || {})
      setResponseBody(prettyJSON(response.data))
      setResponseStatus(response.status)
    } catch (err) {
      setResponseHeaders(err.headers || {})
      setResponseStatus(err.status)
      setResponseBody(prettyJSON(err.data))
    } finally {
      const respTime = Date.now() - startTime
      setResponseTime(respTime)
    }
  }, [requestBody, requestBodyTab, requestBodyText, requestFormItems, requestHeaders, requestMethod, requestUrl, requestUrlParams])

  const handleRequestTabSelect = ({ key="" }="") => {
    setSelectedTab(key)
  }

  const handleRespTabSelect = ({ key="" }="") => {
    setSelectedRespTab(key)
  }

  const handleRequestBodyTabSelect = ({ key="" }) => {
    setRequestBodyTab(key)
  }

  const updateRequestHeader = (header, value, index) => {
    const nextHeaders = [...requestHeaders]
    nextHeaders[index] = {...nextHeaders[index], header, value}

    //Handle adding next header item
    const lastHeader = nextHeaders[nextHeaders.length - 1] || {}
    if (lastHeader.header || lastHeader.value) {
      nextHeaders.push({})
    }

    setRequestHeaders(nextHeaders)
  }

  const updateRequestParam = (param, value, index) => {
    const nextParams = [...requestUrlParams]
    nextParams[index] = {param, value}

    //Handle adding next header item
    const lastParam = nextParams[nextParams.length - 1] || {}
    if (lastParam.param || lastParam.value) {
      nextParams.push({})
    }

    setRequestUrlParams(nextParams)
  }

  const removeRequestHeader = (index) => {
    const nextHeaders = [...requestHeaders.slice(0, index), ...requestHeaders.slice(index + 1)]

    //Handle adding next header item
    const lastHeader = nextHeaders[nextHeaders.length - 1] || {}
    if (lastHeader.header || lastHeader.value || nextHeaders.length === 0) {
      nextHeaders.push({})
    }

    setRequestHeaders(nextHeaders)
  }

  const removeRequestParam = (index) => {
    const nextParams = [...requestUrlParams.slice(0, index), ...requestUrlParams.slice(index + 1)]

    //Handle adding next header item
    const lastParam = nextParams[nextParams.length - 1] || {}
    if (lastParam.param || lastParam.value || nextParams.length === 0) {
      nextParams.push({})
    }

    setRequestUrlParams(nextParams)
  }

  const updateRequestFormItem = (param, value, index) => {
    const nextParams = [...requestFormItems]
    nextParams[index] = {param, value}

    //Handle adding next header item
    const lastParam = nextParams[nextParams.length - 1] || {}
    if (lastParam.param || lastParam.value) {
      nextParams.push({})
    }

    setRequestFormItems(nextParams)
  }

  const removeRequestFormItem = (index) => {
    const nextParams = [...requestFormItems.slice(0, index), ...requestFormItems.slice(index + 1)]

    //Handle adding next header item
    const lastParam = nextParams[nextParams.length - 1] || {}
    if (lastParam.param || lastParam.value || nextParams.length === 0) {
      nextParams.push({})
    }

    setRequestFormItems(nextParams)
  }

  const handleRequestBodyChange = (requestBody) => {
    setRequestBody(requestBody)
  }

  const handleRequestBodyTextChange = (requestBody) => {
    setRequestBodyText(requestBody)
  }

  const handleAuthTokenRequest = async () => {
    const authKeyRegex = /^authorization$/i
    //Fetch token with clientId and clientSecret, add to request headers
    if (authClientId && authClientSecret && authScopes.length) {
      try {
        const accessTokenResp = await requestClientCredentialsToken(authClientId, authClientSecret, authScopes)
        const {access_token, message} = accessTokenResp || {}
        if (access_token) {
          //Add to header
          const authHeader = {header: "Authorization", value: `Bearer ${access_token}`}
          const authHeaderIndex = requestHeaders.findIndex(({header}) => authKeyRegex.test(header))
          //remove existing auth header
          let nextHeaders = authHeaderIndex > -1
            ? [...requestHeaders.slice(0, authHeaderIndex), ...requestHeaders.slice(authHeaderIndex + 1)]
            : [...requestHeaders]

          //add new auth header to top
          nextHeaders.unshift(authHeader)
          setRequestHeaders(nextHeaders)

          addMessage({
            target: GLOBAL_NOTIFICATIONS,
            text: "Access Token Generated",
            subtext: "Added as bearer token to request headers",
            type: MESSAGE_TYPE_SUCCESS,
            timeout: 3000
          })
        } else {
          addMessage({
            target: GLOBAL_NOTIFICATIONS,
            text: "Failed to generate access token",
            subtext: message,
            type: MESSAGE_TYPE_ERROR
          })
        }
      } catch (err) {
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "Failed to generate access token",
          subtext: err.message,
          type: MESSAGE_TYPE_ERROR
        })
      }
    }
  }

  const handleRevokeAuthTokenRequest = async () => {
    if (authClientId && authClientSecret) {
      const accessToken = getAccessTokenFromHeaders()
      if (!accessToken) return
      try {
        const revokeTokenResp = await revokeTokenRequest(accessToken, authClientId, authClientSecret)
        if (revokeTokenResp) {
          // Remove auth header
          const authKeyRegex = /^authorization$/i
          const nextHeaders = requestHeaders.filter(({header}) => !authKeyRegex.test(header))
          setRequestHeaders(nextHeaders)

          addMessage({
            target: GLOBAL_NOTIFICATIONS,
            text: "Access Token Revoked",
            subtext: "The bearer token has been removed from the request headers.",
            type: MESSAGE_TYPE_SUCCESS,
            timeout: 3000
          })
        } else {
          addMessage({
            target: GLOBAL_NOTIFICATIONS,
            text: "Failed to revoke access token",
            type: MESSAGE_TYPE_ERROR
          })
        }
      } catch (err) {
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "Failed to revoke access token",
          subtext: err.message,
          type: MESSAGE_TYPE_ERROR
        })
      }
    }
  }

  const getAccessTokenFromHeaders = () => {
    const authKeyRegex = /^authorization$/i
    const {value: bearer=""} = requestHeaders.find(({header}) => {
      return authKeyRegex.test(header)
    }) || {}
    const accessToken = bearer.replace(/bearer\s+/i, "")
    return accessToken
  }

  const handleAuthScopeChange = (e, scopes="") => {
    const scopeList = scopes.trim().split(/,?\s+/).filter(scope => scope)
    setAuthScopes(scopeList)
  }

  const updateTabName = ({method=requestMethod, url=requestUrl}={}) => {
    updateTabInBelt({
      id: tab.id,
      type: tab.type,
      prevId: tab.id,
      name: `${method.toUpperCase()} /${url.length > 10 ? "..." : ""}${url.slice(-10)}`
    })
  }

  const handleMethodChange = (method) => {
    setRequestMethod(method)
    updateTabName({method})
  }

  const handleUrlChange = (e, url) => {
    setRequestUrl(url)
  }

  const handleUrlBlur = () => {
    updateTabName()
  }

  const makeMainBody = () => {
    const formattedTabs = requestDetailsTabs.map((tab={}) => {
      const {key, label} = tab
      let count = 0
      switch (key) {
      case REQUEST_DETAILS.QUERY:
        count = requestUrlParams.filter(({param}) => param).length
        break
      case REQUEST_DETAILS.HEADERS:
        count = requestHeaders.filter(({header}) => header).length
        break
      }
      return {
        ...tab,
        label: `${label}${count > 0 ? ` (${count})` : ""}`
      }
    })
    const selectedTabIndex = requestDetailsTabs.findIndex(({id}) => id === selectedTab)
    return <div style={styles.request} className="request-body">
      <div style={styles.requestForm}>
        <UnityDropdown
          style={styles.dropdown}
          id='request-method-field'
          name={"resourceMethod"}
          inputType='single-select'
          options={methodOptions}
          selected={[requestMethod]}
          onValueChange={handleMethodChange}
          preventUnselect={true}
        />
        <UnityTextInput
          hideErrors
          id={"request-url"}
          style={styles.requestUrl}
          value={requestUrl}
          onChange={handleUrlChange}
          onBlur={handleUrlBlur}
        />
        <UnityButton
          label='Send'
          id='request-submit-button'
          type='primary'
          onClick={submitRequest}
          style={styles.requestSubmit}
        />
      </div>

      <div style={styles.details} className="request-details">
        <UnityPageHeader
          tabs={formattedTabs}
          selectedTab={selectedTabIndex}
          onTabSelect={handleRequestTabSelect}
        />
        <div style={styles.detailsContent} className="request-details-content">
          {selectedTab === REQUEST_DETAILS.QUERY && renderRequestParams()}
          {selectedTab === REQUEST_DETAILS.HEADERS && renderRequestHeaders()}
          {selectedTab === REQUEST_DETAILS.AUTH && renderAuth()}
          {selectedTab === REQUEST_DETAILS.BODY && renderRequestBody()}
        </div>
      </div>
    </div>
  }

  const renderRequestHeaders = () => {
    return <div style={styles.requestHeaders} id="request-headers-form">
      <div style={styles.headersForm}>
        {requestHeaders.map(({header="", value="", studioAuth=false}={}, index) => (
          <div style={styles.headerItemContainer} key={index} id={`request-header-item-${index}`}>
            <div style={styles.headerItemInputs}>
              <UnityTextInput
                value={header}
                id={`request-header-${header}`}
                style={{...styles.headerInput, ...styles.headerLabelInput}}
                hideErrors
                placeholder="Header"
                onChange={(e, headerLabel) => {updateRequestHeader(headerLabel, value, index)}}
              ></UnityTextInput>
              <UnityTextInput
                value={value}
                id={`request-header-value-${header}`}
                style={styles.headerInput}
                hideErrors
                placeholder="Value"
                onChange={(e, headerValue) => {updateRequestHeader(header, headerValue, index)}}
              ></UnityTextInput>
              <UnityButton
                id={`request-header-delete-button-${index}`}
                className="request-header-delete-button"
                type='borderless'
                centerIcon="unity:close"
                onClick={() => {removeRequestHeader(index)}}
              ></UnityButton>
            </div>
            {studioAuth && <div style={styles.headerItemSubtext}>
              <UnityTypography>From your Studio session</UnityTypography>
            </div>}
          </div>
        ))}
      </div>
    </div>
  }

  //NOTE: requestParams may be strings or arrays of strings. Need to be able to handle both
  // For now, just support strings
  const renderRequestParams = () => {
    return <div style={styles.requestHeaders} id="request-params-form">
      <div style={styles.headersForm}>
        {requestUrlParams.map(({param="", value=""}={}, index) => (
          <div style={styles.headerItem} key={index} id={`request-param-item-${index}`}>
            <UnityTextInput
              value={param}
              id={`request-param-${index}`}
              style={{...styles.headerInput, ...styles.headerLabelInput}}
              hideErrors
              placeholder="Parameter"
              onChange={(e, paramLabel) => {updateRequestParam(paramLabel, value, index)}}
            ></UnityTextInput>
            <UnityTextInput
              value={value}
              id={`request-param-value-${index}`}
              style={styles.headerInput}
              hideErrors
              placeholder="Value"
              onChange={(e, paramValue) => {updateRequestParam(param, paramValue, index)}}
            ></UnityTextInput>
            <UnityButton
              id={`request-param-delete-button-${index}`}
              className="request-param-delete-button"
              type='borderless'
              centerIcon="unity:close"
              onClick={() => {removeRequestParam(index)}}
            ></UnityButton>
          </div>
        ))}
      </div>
    </div>
  }

  const renderAuth = () => {
    const accessToken = getAccessTokenFromHeaders()
    const enableTokenRevokeBtn = accessToken && authClientId && authClientSecret
    return <div style={styles.requestHeaders}>
      <div style={styles.authForm}>
        <div style={styles.headerItem}>
          <UnityTextInput
            id="auth-client-id"
            value={authClientId}
            style={{...styles.headerInput, ...styles.headerLabelInput}}
            hideErrors
            placeholder="Client ID"
            onChange={(e, clientId) => {setAuthClientId(clientId)}}
          />
          <UnityTextInput
            id="auth-client-secret"
            value={authClientSecret}
            style={styles.headerInput}
            hideErrors
            placeholder="Client Secret"
            onChange={(e, secret) => {setAuthClientSecret(secret)}}
          />
        </div>

        <div style={styles.headerItem}>
          <UnityTextInput
            id="http-inspector-auth-scopes"
            style={styles.headerInput}
            hideErrors
            placeholder="Scopes"
            onChange={handleAuthScopeChange}
          />
        </div>

        <div style={styles.authSubmit}>
          <UnityButton
            id="http-inspector-auth-revoke"
            type='secondary'
            label="Revoke Token"
            onClick={handleRevokeAuthTokenRequest}
            disabled={!enableTokenRevokeBtn}
            style={styles.revokeTokenBtn}
          />
          <UnityButton
            id="http-inspector-auth-submit"
            type='primary'
            label="Get Token"
            onClick={handleAuthTokenRequest}
            disabled={!authClientId || !authClientSecret || authScopes.length === 0}
          />
        </div>
      </div>
    </div>
  }

  const renderRequestBody = () => {
    const selectedTabIndex = bodyTabs.findIndex(({id}) => id === requestBodyTab)
    return <div style={styles.requestBody} id="request-body-form">
      <UnityPageHeader
        tabs={bodyTabs}
        selectedTab={selectedTabIndex}
        onTabSelect={handleRequestBodyTabSelect}
      />
      <div style={styles.reqBodyContent} id="request-body-content">
        {requestBodyTab === CONTENT_TYPES.BODY_JSON &&
          <CodeEditor
            mode={"json5"}
            embedded
            input={{
              onChange: handleRequestBodyChange,
              value: requestBody
            }}
            name='requestBody'
            label='request-body-json'
            error={requestBody ? validateJSON(requestBody).error : undefined}
          />
        }
        {requestBodyTab === CONTENT_TYPES.BODY_TEXT &&
          <CodeEditor
            mode={"text"}
            embedded
            input={{
              onChange: handleRequestBodyTextChange,
              value: requestBodyText
            }}
            name='requestBody'
            label='request-body-text'
            showLineNumbers={false}
          />
        }

        {requestBodyTab === CONTENT_TYPES.BODY_FORM &&
          <div style={styles.headersForm}>
            {requestFormItems.map(({param="", value=""}={}, index) => (
              <div style={styles.headerItem} key={index} id={`request-form-item-${index}`}>
                <UnityTextInput
                  value={param}
                  id={`request-form-name-${index}`}
                  style={{...styles.headerInput, ...styles.headerLabelInput}}
                  hideErrors
                  placeholder="Name"
                  onChange={(e, paramLabel) => {updateRequestFormItem(paramLabel, value, index)}}
                ></UnityTextInput>
                <UnityTextInput
                  value={value}
                  id={`request-form-value-${index}`}
                  style={styles.headerInput}
                  hideErrors
                  placeholder="Value"
                  onChange={(e, paramValue) => {updateRequestFormItem(param, paramValue, index)}}
                ></UnityTextInput>
                <UnityButton
                  id={`request-form-delete-button-${index}`}
                  className="request-form-delete-button"
                  type='borderless'
                  centerIcon="unity:close"
                  onClick={() => {removeRequestFormItem(index)}}
                ></UnityButton>
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  }

  const makeDetailsBody = () => {
    const formattedTabs = responseTabs.map((tab={}) => {
      const {key, label} = tab
      let count = 0
      switch (key) {
      case RESPONSE_HEADERS:
        count = Object.keys(responseHeaders).length
        break
      }
      return {
        ...tab,
        label: `${label}${count > 0 ? ` (${count})` : ""}`
      }
    })
    const selectedTabIndex = responseTabs.findIndex(({id}) => id === selectedRespTab)
    return <div style={styles.details} id="http-inspector-response">
      <UnityPageHeader
        tabs={formattedTabs}
        selectedTab={selectedTabIndex}
        onTabSelect={handleRespTabSelect}
        leftContent={renderResponseStats()}
      />

      <div style={styles.detailsContent} className="response-content">
        {selectedRespTab === RESPONSE_HEADERS && renderResponseHeaders()}
        {selectedRespTab === RESPONSE && renderResponseBody()}
      </div>
    </div>
  }

  const renderResponseStats = () => {
    return <UnityTypography>
      <div style={styles.responseStats} id="http-inspector-response-stats">
        <div id="http-inspector-response-status-container" style={styles.respStat}>
          <div style={styles.statLabel}>Status:</div>
          <div id="http-inspector-response-status" style={styles.statVal}>{responseStatus}</div>
        </div>
        {responseTime &&
          <div id="http-inspector-response-time-container" style={styles.respStat}>
            <div style={styles.statLabel}>Time:</div>
            <div id="http-inspector-response-time" style={styles.statVal}>{responseTime} ms</div>
          </div>
        }
      </div>
    </UnityTypography>
  }

  const renderResponseBody = () => {
    return <div style={styles.requestBody} id="http-inspector-response-body">
      <CodeEditor
        mode={"json"}
        embedded
        input={{
          value: responseBody
        }}
        name='responseBody'
        label='request-body'
        readOnly={true}
      />
    </div>
  }

  const renderResponseHeaders = () => {
    return <div style={styles.requestBody} id="http-inspector-response-headers">
      <UnityTable
        id="response-headers-table"
        columns={HEADER_COLUMNS}
        style={styles.table}
        data={Object.entries(responseHeaders).map(([headerKey, value]) => {
          return {id: headerKey, header: headerKey, value}
        })}
        emptyText={"No data found"}
        keyExtractor={datum => datum.id}
      />
    </div>
  }

  //NOTE: Cannot update panes after mount
  const makePanes = () => {
    const tablePane = {
      key: MAIN,
      body: makeMainBody()
    }
    const detailPane = {
      key: DETAILS,
      body: makeDetailsBody()
    }

    return [tablePane, detailPane]
  }

  return (
    <div style={styles.panel} className="http-inspector">
      <UnityMultiPane
        style={styles.splitPane}
        visiblePanes={getVisiblePanes()}
        collapsedPanes={[]}
        labels={getPaneLabels()}
        panes={makePanes()}
      />
    </div>
  )
}

export default connect(stateToProps, {
})(HttpInspector)

const styles = {
  panel: {
    flex: 1,
    height: "100%",
    position: "relative"
  },
  splitPane: {
    "--border-color": "#636363",
    "--pane-z-index": 3
  },
  request: {
    flex: 1,
    overflow: "hidden",
    minWidth: 0,
    display: "flex",
    flexDirection: "column"
  },
  detailsPanel: {
    flex: 1,
    overflowX: "hidden",
    overflowY: "auto",
    minWidth: 0
  },
  requestForm: {
    flex: 0,
    display: "flex",
    margin: 4,
    marginTop: 12
  },
  requestBodyText: {
    flex: 1
  },
  dropdown: {
    "--dropdown-width": "75px",
    marginRight: 4
  },
  requestUrl: {
    flex: 1
  },
  requestSubmit: {
    marginLeft: 4
  },
  headersForm: {
    flex: 1,
    margin: 12,
    marginRight: 2
  },
  headerItem: {
    display: "flex",
    alignSelf: "stretch",
    alignItems: "center",
    marginBottom: 8
  },
  headerItemContainer: {
    flex: 1,
    marginBottom: 8
  },
  headerItemInputs: {
    display: "flex",
    alignSelf: "stretch",
    alignItems: "center",
  },
  headerInput: {
    flex: 1,
  },
  headerLabelInput: {
    marginRight: 12
  },
  authForm: {
    flex: 1,
    margin: 12
  },
  authSubmit: {
    display: "flex",
    justifyContent: "flex-end"
  },
  details: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 0
  },
  detailsContent: {
    flex: 1,
    overflowY: "auto",
    minHeight: 0,
    display: "flex",
    flexDirection: "column"
  },
  requestBody: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  reqBodyContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  revokeTokenBtn: {
    marginRight: 12
  },
  responseStats: {
    display: "flex"
  },
  responseBody: {
    flex: 1,
    minWidth: 0
  },
  respStat: {
    display: "flex",
    marginRight: 24
  },
  statLabel: {
    marginRight: 8
  },
  table: {
    flex: 1
  }
}