import {
  UnityButton,
  UnityPopover,
  UnitySelectMenu,
  UnityTypography
} from "libs/UnityCoreReact"
import React, { useRef, useState } from "react"
import { v4 as uuid } from "uuid"

import { FUNCTIONS, HTTP_INSPECTOR, MQTT_INSPECTOR } from "constants/routes"
import { addTabToBelt, NEW_FUNCTION_LOG } from "utils/utilityBelt"

const paneOptions = [
  {id: NEW_FUNCTION_LOG, label: "Function Log"},
  {id: MQTT_INSPECTOR, label: "MQTT Inspector"},
  {id: HTTP_INSPECTOR, label: "API Inspector"}
]

const UtilityFooterAdd = () => {
  const addButtonRef = useRef(null)
  const [isExpanded, setExpanded] = useState(false)

  const getPopoverOffset = () => {
    return [0, 2]
  }
  const btnElement = addButtonRef?.current?.buttonRef?.current
  const handleCloseMenu = () => {
    setExpanded(false)
  }

  const handlePanelSelect = (panel) => {
    if (panel === NEW_FUNCTION_LOG) {
      handleFunctionSelect(NEW_FUNCTION_LOG)
    } else if (panel === MQTT_INSPECTOR) {
      handleMqttInspectorSelect()
    } else if (panel === HTTP_INSPECTOR) {
      handleHttpInspectorSelect(uuid())
    }
  }
  const handleFunctionSelect = (functionName) => {
    addTabToBelt({type: FUNCTIONS, id: functionName})
    handleCloseMenu()
  }
  const handleMqttInspectorSelect = () => {
    addTabToBelt({type: MQTT_INSPECTOR, id: MQTT_INSPECTOR})
    handleCloseMenu()
  }
  const handleHttpInspectorSelect = (uid) => {
    addTabToBelt({type: HTTP_INSPECTOR, id: uid})
    handleCloseMenu()
  }

  //TODO: add popover menu back once other types of utility panels are supported
  return (
    <div>
      <UnityPopover
        withClose={false}
        closeOnOutsideClick={true}
        show={isExpanded}
        popoverContent={<>
          <div style={styles.addTitle} key="belt-add-menu-title">
            <UnityTypography>Utility Belt</UnityTypography>
          </div>
          <UnitySelectMenu
            key="belt-add-menu"
            options={paneOptions}
            onMenuClick={handlePanelSelect}
            borderless={true}
            style={styles.menu}
          />
        </>}
        offsetModifier={getPopoverOffset}
        onClose={handleCloseMenu}
        referenceElement={btnElement}
        style={styles.popover}
      />
      <UnityButton
        id="utility-belt-add-button"
        type="borderless"
        centerIcon="unity:add"
        style={styles.addButton}
        onClick={() => setExpanded(!isExpanded)}
        ref={addButtonRef}
      />
    </div>
  )
}

export default UtilityFooterAdd

const styles = {
  addButton: {
    "--icon-button-size": "20px",
    marginRight: 4
  },
  popover: {
    "--popover-padding": 0,
    "--popover-z-index": 200
  },
  addTitle: {
    padding: 6,
    borderBottom: "1px solid var(--light-gray-1-color)"
  },
  menu: {
    width: "100%"
  }
}