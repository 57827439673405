import { cloneDeep } from "lodash"

import { del, get, parseApiErrorMessage, post, put } from "./api"
import { addMessage, GLOBAL_NOTIFICATIONS, MESSAGE_TYPE_ERROR, MESSAGE_TYPE_SUCCESS } from "./notifications"

export async function getCustomQueriesRequest() {
  const searchParams = new URLSearchParams()
  searchParams.append("type", "iot_studio")

  const { data } = await get("/variables?" + searchParams.toString())
  return data
}

export async function getCustomQueryRequest(id) {
  const { data } = await get(`/variables/${id}`)

  if (data.type !== "iot_studio") {
    throw new Error("Custom query doesn't exist")
  }

  return data
}

export async function createCustomQueryRequest(body) {
  const newBody = cloneDeep(body)
  newBody.type = "iot_studio"

  const { data } = await post("/variables", newBody)
  return data
}

export async function updateCustomQueryRequest(body) {
  const newBody = cloneDeep(body)
  newBody.type = "iot_studio"

  const { data } = await put(`/variables/${newBody.name}`, newBody)

  return data
}

export async function deleteCustomQueriesRequest(params) {
  return await del("/variables", params)
}

export async function customQueryRequest(body, showNotification=true) {
  const endpoint = "/query/cursor"
  try {
    const response = await post(endpoint, body)
    const { data } = response
    showNotification && addMessage({
      target: GLOBAL_NOTIFICATIONS,
      text: "Query processed!",
      type: MESSAGE_TYPE_SUCCESS
    })
    return data
  } catch (error) {
    showNotification && addMessage({
      target: GLOBAL_NOTIFICATIONS,
      text: "Query could not be processed",
      subtext: parseApiErrorMessage(error),
      type: MESSAGE_TYPE_ERROR
    })
  }
}
