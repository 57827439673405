import {
  UnityButton,
} from "libs/UnityCoreReact"
import React, {useEffect, useRef, useState} from "react"
import { connect } from "react-redux"

import {colors} from "assets/stylesheets/common"
import { setMqttSubscriptions } from "reduxModules/mqttInspector"
import { getSpaceId } from "utils/auth"
import { openMqttSubscriptionsModal } from "utils/utilityBelt"


const stateToProps = ({
  mqttInspector: {
    subscriptions=[]
  }={}
}) => ({
  subscriptions
})

const MqttInspectorSubscriptions = ({
  subscriptions=[]
}) => {
  const space = useRef(getSpaceId())
  const defaultTopics = [
    `spaces/${space.current}/#`,
  ]
  const [activeFilter, setActiveFilter] = useState(false)

  useEffect(() => {
    handleSubscriptionChange()
  }, [subscriptions])

  const handleSubscriptionChange = () => {
    //activate icon color if no longer subscribing to the default topics
    const nextActiveFilter = defaultTopics.reduce((isActive, defaultTopic) => {
      // Foreach default topic, check if it is subscribed to. If yes, return false (filter Not active) otherwise return true
      return !(!isActive && subscriptions.includes(defaultTopic))
    }, false)
    setActiveFilter(nextActiveFilter)
  }

  const getButtonStyles = () => {
    return activeFilter
      ? {...styles.button, ...styles.activeFilter}
      : styles.button
  }

  return (
    <>
      <UnityButton
        key="mqtt-inspector-subscriptions-button"
        id="mqtt-inspector-subscriptions-button"
        title='MQTT Subscriptions'
        centerIcon={"communication:rss-feed"}
        type='borderless'
        onClick={openMqttSubscriptionsModal}
        style={getButtonStyles()}
      />
    </>
  )
}

const styles = {
  button: {},
  activeFilter: {
    "--button-borderless-text-color": colors.primary1
  }
}

// export default MqttInspectorSubscriptions
export default connect(stateToProps, {setMqttSubscriptions})(MqttInspectorSubscriptions)