import { UnityButton } from "libs/UnityCoreReact"
import React from "react"

import Dialog from "components/common/Dialog"
import { CONTACT_US_URL } from "constants/routes"


function FreeTrialModal({
  show = false,
  handleToggle=()=>{},
}) {

  const closeModal = () => {
    handleToggle(false)
  }

  const freeTrialModalText = (
    <div style={styles.modalTextBox}>
      <p>- If you already have your AltairOne Licenses linked, access the AU Management section</p>
      <p>- If you need to set up an AltairOne Licenses, contact us <a id="idp-link" href={CONTACT_US_URL}
        target="_blank" rel="noopener noreferrer"
        className="homepageLinks"> here. </a></p>
    </div>
  )

  return (
    <Dialog
      id={"contact-us-free-trial-modal"}
      show={show}
      toggle={handleToggle}
      title={"Upgrade to Commercial Version"}
      body={freeTrialModalText}
      bottom={(
        <div >
          <UnityButton
            id="free-trial-modal-cancel-button"
            label="Close"
            onClick={closeModal}
            style={{...styles.modalButton, ...styles.modalCloseButton}}
            type="secondary"
          />
        </div>
      )}
    />
  )
}

const styles = {
  modalTextBox: {
    minWidth: "275px",
    margin: "12px"
  },
  modalButton: {
    marginLeft: 4,
  },
  modalCloseButton: {
    "--button-color": "var(--black-text-color)"
  },
}
export default FreeTrialModal
