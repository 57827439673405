import { cloneDeep, setWith, unset } from "lodash"
import getFrom from "lodash/get"
import omit from "lodash/omit"

import { ASCENDING, DESCENDING } from "constants/things"
import { del, get, getThingBaseUrl, post, put } from "utils/api"
import { getTableFilters } from "utils/storage"

import { categoriesColumn, EQUAL_OPERATOR, labelsColumn, modifiedColumn, titleColumn, uidColumn } from "./tableColumns"



// THING REQUESTS

export async function getThingsRequest(params, categoryName) {
  const url = getThingBaseUrl(categoryName) + "/things"
  const { data } = await get(url, params)
  return data
}

export async function getThingRequest(id, categoryName) {
  const url = getThingBaseUrl(categoryName) + `/things/${id}`
  const { data } = await get(url, null)
  return data
}

export async function createThingRequest(thing, category) {
  const url = getThingBaseUrl(category) + "/things"
  const { data } = await post(url, thing)
  return data
}

export async function updateThingRequest(id, updatedSchema, category) {
  const url = getThingBaseUrl(category) + `/things/${id}`
  const updatedThing = await put(url, updatedSchema)
  return updatedThing.data
}

export async function deleteThingRequest(id, category) {
  const url = getThingBaseUrl(category) + `/things/${id}`
  const response = await del(url, null)
  //TODO: This is temporarily commented out until api response changes
  // if (response.status !== 204) throw response // delete app requests return null when successful, and an error object on failure
  if (response) throw response //TODO: replace this line with the one above when api response changes
  return response
}


// THING PROPERTIES REQUESTS

export async function updateThingPropertiesRequest(id, properties, category) {
  const url = getThingBaseUrl(category) + `/things/${id}/properties`
  const { data } = await put(url, properties)
  return data
}

export async function getThingPropertiesHistoryRequest(thingId = "", property = "", params = null) {
  const url = getThingBaseUrl(null) + `/things/${thingId}/properties-history` + (property ? `/${property}` : "")

  const { data } = await get(url, params)

  return data
}

// THING ACTION REQUESTS

export async function getThingActionsRequest(thingId, category) {
  const url = getThingBaseUrl(category) + `/things/${thingId}/actions`
  const { data } = await get (url, null)
  return data
}

export async function deleteActionInstanceRequest(thingId, actionName, instanceId, category) {
  const url = getThingBaseUrl(category) + `/things/${thingId}/actions/${actionName}/${instanceId}`
  const response = await del(url, null)
  if (response) throw response
  return response
}

export async function runActionRequest(thingId, action, category) {
  const url = getThingBaseUrl(category) + `/things/${thingId}/actions`
  const { data } = await post(`${url}/${Object.keys(action)[0]}`, action)
  return data
}


// THING EVENT REQUESTS

export async function getThingEventsRequest(thingId, category) {
  const url = getThingBaseUrl(category) + `/things/${thingId}/events`
  const { data } = await get (url, null)
  return data
}

export async function getThingEventMetricsRequest(thingId, category) {
  const url = getThingBaseUrl(category) + `/things/${thingId}/events/metrics`
  const { data } = await get (url, null)
  return data

}

export async function deleteEventEntryRequest(thingId, eventName, entryId, category) {

  const url = getThingBaseUrl(category) + `/things/${thingId}/events/${eventName}/${entryId}`
  const response = await del(url, null)
  if (response) throw response
  return response
}

// OTHER REQUESTS

export async function resetSecretRequest(category, thingId) {
  const url = category ? `/categories/${category}/things/${thingId}/reset-secret` : `/things/${thingId}/reset-secret`
  const { data } = await post(url, null)
  return data
}

// CONSTANTS


export const LINK_COLUMN = "LINK_COLUMN"


// FUNCTIONS

export const getLinkKey = (key) => `${key} (link)`

export const initialColumns = [
  titleColumn,
  categoriesColumn,
  labelsColumn,
  uidColumn,
  modifiedColumn
]

const initialColMap = new Map(
  initialColumns.map((col) => [col.key, col.label])
)

//Convert camelcase thing property key name to Title Case
function propKeyToLabel(propKey) {
  // adding space between strings
  const result = propKey.replace(/([A-Z])/g, " $1")
  // converting first character to uppercase and join it to the final string
  return result.charAt(0).toUpperCase() + result.slice(1)
}

/**
 * Get array of columns for Things List
 * @param {array} things - array of things
 * @param {array} originalColumns - Optional - array of existing columns to preserve order
 * @return {array} sorted thing columns
 *  */
export function getThingColumns(
  things = [],
  originalColumns = initialColumns,
  keepOriginalColumns = false
) {
  const initColMap = new Map(initialColMap)

  //set originalColumns to initialColumns if empty array
  if (originalColumns.length === 0) {
    originalColumns = initialColumns
  }

  const propMap = things.reduce((lib, { properties = {}, links = [] }) => {
    //Loop over properties object, add to Map (key is property key name, value is title if it exists or the property key)
    //If title is not defined, generate it from property key
    for (let propKey in properties) {
      const title = getFrom(properties, `${propKey}.title`, "")
      lib.set(propKey, title || propKey)
    }
    // add columns for each link relation
    links.forEach((link) => {
      const { rel } = link
      if (!lib[rel]) lib.set(getLinkKey(rel), LINK_COLUMN)
    })
    return lib
  }, new Map())

  // labelMap has all properties && all initial columns (title, uid and labels)
  const labelMap = new Map([...initColMap, ...propMap])
  let columns = []
  //Add columns specified in originalColumns in order
  originalColumns.forEach((col) => {
    const { key, showFilter, singleFilter } = col
    if (labelMap.has(key)) {
      const label = labelMap.get(key)
      const isLink = label === LINK_COLUMN
      const nextCol = {
        ...col,
        label: isLink ? key : label,
        showFilter: showFilter || propMap.has(key), //Allow property columns to be filtered
        singleFilter: isLink || singleFilter,
        hideSort: true,
      }
      if (isLink) {
        nextCol.type = LINK_COLUMN
        nextCol.operators = [EQUAL_OPERATOR]
      } else if (key === "title" || key === "id") nextCol.hideSort = false
      columns.push(nextCol)
      labelMap.delete(key)
    } else if (keepOriginalColumns) {
      columns.push(col)
    }
  })

  //Add remaining columns in alphabetical order by their label
  const remainingCols = Array.from(labelMap)
    .map(([propKey = "", title = ""] = []) => {
      const isLink = title === LINK_COLUMN
      const col = {
        key: propKey,
        label: isLink ? propKey : title || propKeyToLabel(propKey),
        showFilter: propMap.has(propKey),
        singleFilter: isLink || undefined,
        hideSort: true,
      }
      if (isLink) {
        col.type = LINK_COLUMN
        col.operators = [EQUAL_OPERATOR]
      } else if (propKey === "title" || propKey === "uid") col.hideSort = false

      return col
    })
    .sort(({ label: labelA = "" }, { label: labelB = "" }) => {
      if (labelA < labelB) {
        return -1
      }
      if (labelA > labelB) {
        return 1
      }
      return 0
    })

  return [...columns, ...remainingCols]
}

export function formatSchemaToUpdate(thingSchema={}) {
  // Remove unwanted attributes
  return omit(thingSchema, ["created", "id", "modified", "status"])
}


// MISC

export function getFormattedSchemaValues(values) {
  let newValues = {}
  const { type } = values
  Object.entries(values).forEach(([key, val]) => {
    if (val === "" || val === undefined) return // if no value was provided, no need to add to schema (empty string causes API error, for example)
    if(key === "propertyKey" || key === "value") return
    if(key === "minimum" || key === "maximum") {
      if (type === "number" || type === "integer") {
        newValues[key] = Number(val)
        return
      }
    }
    if((key === "minItems" || key === "maxItems") && type === "array") {
      newValues[key] = Number(val)
      return
    }
    if(key === "readOnly" && !val) return
    if(key==="properties" && type !== "object") return
    if(key==="items" && type !== "array") return
    newValues[key] = val
  })
  return newValues
}

export function getPropertiesPathFromSchemaPath(path) {
  // Schema path includes "properties" key at odd indexes, but properties path doesn't.
  // We need to filter the schema path to keep only even keys.
  return path.filter((key, index) => index % 2)
}

export const editPortionOfSchema = ({ keyPath=[], schema, values }) => {
  let updatedObject = cloneDeep(schema)
  values ? setWith(updatedObject, keyPath, getFormattedSchemaValues(values), Object) : unset(updatedObject, keyPath)
  return updatedObject
}

const getFormattedLinkRel = key => key.replace(/ \(link\)$/, "")

export const getFormattedLinks = (thing) =>
  thing.links?.reduce((links, currentLink) => {
    const { rel, href } = currentLink
    const formattedRel = getLinkKey(rel)
    links[formattedRel] = [...(links[formattedRel] || []), href]
    return links
  }, {})

export function getColumnFilterQuery(columnFilters={}, columns) {
  let filters = {}
  for(let colKey in columnFilters) {
    const {[colKey]: colFilters=[]} = columnFilters

    //skip if column has no filters
    if (colFilters.length === 0) continue

    switch (colKey) {
    case "title":
    case "@type":
      if (colFilters[0]?.value) {
        filters[colKey] = colFilters[0].value
      }
      break
    case "categories": {
      const inCategoryArr = colFilters.filter(f => f.operator == "in_category")

      if(inCategoryArr.length > 0){
        let inCatFilters = []
        inCategoryArr.forEach(cat => (cat.value == "true" || cat.value == "false") && inCatFilters.push(cat.value))

        filters["in_category"] = inCatFilters
      }

      break
    }
    case "uid":
      filters["thingId[]"] = colFilters.map(f => f.value)
      break
    case "model":
      filters[colKey] = columnFilters[colKey]
      break
    case "version":
      filters[colKey] = columnFilters[colKey]
      break
      // handle link filters
    default:
      if (columns.find(col => col.key === colKey)?.type === LINK_COLUMN) {
        filters["links.href"] = colFilters[0]?.value
        filters["links.rel"] = getFormattedLinkRel(colKey)
      } else {
        filters[`property:${colKey}`] = colFilters.map(({operator, value}) =>
          operator === "eq" ? value : `${operator}:${value}`
        )
      }

    }
  }
  return filters
}

export const getFilterQueryParams = (tableName, columns, username) => {
  const columnFilters = getTableFilters(tableName, username) || {}
  const filterQueryParams = getColumnFilterQuery(columnFilters, columns)

  return filterQueryParams
}

export const getSortQuery = sort => {
  const { direction, column } = sort
  let query
  switch (direction) {
  case ASCENDING:
    query = `+${column}`
    break
  case DESCENDING:
    query = `-${column}`
    break
  default:
    break
  }
  return query? { sort: query } : {}
}

export const getTableNameByCategory = (category) => category ?? "no-category"

export const hasColumnFilters = (tableName, username) => {
  const filters = getTableFilters(tableName, username)
  return Object.keys(filters).some(f => !!filters[f].length)
}

export const typeOptions = [
  {
    label: "array",
    id: "array"
  },
  {
    label: "boolean",
    id: "boolean"
  },
  {
    label: "number",
    id: "number"
  },
  {
    label: "integer",
    id: "integer"
  },
  {
    label: "object",
    id: "object"
  },
  {
    label: "string",
    id: "string"
  },
  {
    label: "null",
    id: "null"
  }
]
