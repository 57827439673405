import { createBrowserHistory } from "history"
import React from "react"
import ReactDOM from "react-dom"
import * as serviceWorker from "serviceWorker"
// import "libs/unity-core-bundle.min.js"
import "libs/unity-core-bundle.js"

import configureStore from "store/configureStore"

import "./index.css"
import Root from "./Root"

function init() {
  // dispatch(verifyUser())
}

const history = createBrowserHistory()

const store = configureStore(history)

function checkFFVersion() {
  const userAgent = navigator.userAgent
  // check if Firefox v88
  if (userAgent.match(/(firefox\/88)/i)) {
    alert(
      "This website performs best on newer versions of Firefox.\nPlease update your browser to v89 or beyond for best performance."
    )
  }
}

function startApp() {
  init(store)
  checkFFVersion()
  ReactDOM.render(
    <Root store={store} history={history} />,
    document.getElementById("root")
  )
}

startApp()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
