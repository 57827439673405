import cron from "cron-validate"
import isEqual from "lodash/isEqual"

import { isObject, validateJSON } from "utils/misc"
import { getModelVersionsRequest, INVALID_MODEL_FIELDS } from "utils/modelsBeta"

export const reqValid = value => typeof value !== "undefined" ? undefined : "This field is required"
export const numType = value => isNaN(value) ? "Must be a number" : undefined
export const minMaxValid = (value="", form, field={}) => {
  const {
    property: {
      minimum,
      maximum
    }={}
  } = field

  if (!isNaN(minimum) && Number(value) < minimum)
    return `Cannot be less than ${minimum}`
  if (!isNaN(maximum) && Number(value) > maximum)
    return `Cannot be more than ${maximum}`
}

// list of valid values for @context in Thing Schema
const contextList = [
  "https://www.w3.org/2019/wot/td/v1"
]

export const validateJSONSchema = schema => {
  let errors
  const {
    value,
    error: jsonError
  } = validateJSON(schema)
  if (jsonError) {
    try {
      errors = { thingSchema: `JSON Error: ${jsonError.match(/line (\d+) column (\d+)/)[0]}` }
    } catch (typeError) {
      errors = { thingSchema: jsonError }
    }
  }
  return { value, errors }
}

export const validateModelVersionTitle = title => title?.length > 80 ? "Version title cannot be longer than 80 characters" : undefined

export const validateSchema = ({ thingSchema="{}", cloning=false, titleRequired=true, beta=false}) => {
  let errors = {}
  const { value, errors: jsonError } = validateJSONSchema(thingSchema)

  if (value) {
    const {
      title,
      description="",
      labels,
      properties,
      actions,
      events,
      links,
      "@type": type,
      "@context": context
    } = value

    const titleErrors = (title === undefined && !titleRequired)? null : validateSchemaTitle(title, cloning, beta)
    if (typeof value !== "object" || Array.isArray(value)) errors.thingSchema = "Schema must be a valid JSON object"
    else if (titleErrors) errors.thingSchema = titleErrors
    else if (description.length > 1024) errors.thingSchema = "Description must be less than 1024 characters"
    else if (labels !== undefined) errors.thingSchema = "\"labels\" field is not allowed"
    else if (!!type && !validateAtTypeValue(type)) errors.thingSchema = "@type should be a string"
    else if (context && !contextList.includes(context)) errors.thingSchema = "Invalid @context. Acceptable values are: " + contextList.join(",")
    else if (properties && !(properties instanceof Object) || Array.isArray(properties)) errors.thingSchema = "Properties should be an Object"
    else if (actions && !(actions instanceof Object) || Array.isArray(actions)) errors.thingSchema = "Actions should be an Object"
    else if (events && !(events instanceof Object) || Array.isArray(events)) errors.thingSchema = "Events should be an Object"
    else if (links && !Array.isArray(links)) errors.thingSchema = "Links should be an Array"
    else if (links && validateLinks(links) === "href missing") errors.thingSchema = "Links must contain 'href' (url) property"
    else if (links && validateLinks(links) === "rel missing") errors.thingSchema = "Links must contain 'rel' property"
  }
  else errors = jsonError || {}
  if (Object.keys(errors) > 0) errors._error = { ...errors }
  return errors
}

export const validateSchemaByModel = (thingSchemaString, modelTemplate) => {
  let error
  const keys = [
    "title",
    "description",
    "actions",
    "events",
    "properties",
  ]

  const { value: thingSchema, errors } = validateJSONSchema(thingSchemaString)

  if (errors?.thingSchema) {
    return errors.thingSchema
  }

  keys.some(key => {
    const modelTemplateValue = modelTemplate[key]
    const thingSchemaValue = thingSchema[key]

    if (modelTemplateValue && !isEqual(modelTemplateValue, thingSchemaValue)) {
      const isObject = typeof modelTemplateValue === "object"
      const isEmptyObject = isObject && Object.keys(modelTemplateValue).length === 0

      if (isEmptyObject) {
        error = `Schema "${key}" is disabled by the model`
      } else {
        error = `Schema "${key}" is defined by the model and cannot be changed`
      }
      return true
    }

    return false
  })

  return error
}

const validateAtTypeValue = type => {
  if (type) {
    if (typeof type === "string") return true
    if (
      Array.isArray(type) && type.length > 0 && type.every(v => !!v && typeof v === "string")
    ) return true
  }
  return false
}

const validateLinks = links => {
  if (links.some(l => !l.href)){
    return "href missing"
  } else if (links.some(l => !l.rel)) {
    return "rel missing"
  } else {
    return true
  }
}

export const validateNewModelVersion = (schema, beta=false) => {
  let errors = {}
  const { value: jsonSchema, errors: jsonError } = validateJSONSchema(schema)
  if(jsonError) return jsonError
  else {
    const { title="", description="", template, properties, actions, events, links } = jsonSchema

    const titleErrors = validateModelVersionTitle(title)
    if(titleErrors) return { thingSchema: titleErrors }

    if (properties || actions || events || links) {
      const fieldName = properties ? "properties" : actions ? "actions" : events ? "events" : "links"
      errors.thingSchema = `"${fieldName}" must be defined inside the "template" field`
      return errors
    }

    const descriptionErrors = validateSchemaDescription(description)
    if(descriptionErrors) return { thingSchema: descriptionErrors }

    if(template) {
      if(!isObject(template)) errors.thingSchema = "Template must be an object"
      else {
        const schemaErrors = validateNewSchema(JSON.stringify(template), { titleRequired: false, beta })
        errors = schemaErrors
      }
      if (errors.thingSchema) return errors
    }
  }
  return errors
}

export const validateNewSchema = (schema, options={}) => {
  const errors = validateSchema({ thingSchema: schema, titleRequired: options.titleRequired, beta: options.beta })
  if(!errors.thingSchema) {
    const jsonSchema = JSON.parse(schema)
    for (const field of INVALID_MODEL_FIELDS) {
      if (jsonSchema[field] !== undefined) {
        errors.thingSchema = `"${field}" field is not allowed`
        break
      }
    }
    if (Object.keys(errors) > 0) errors._error = { ...errors }
  }
  return errors
}

export const validateCloneSchema = ({ thingSchema, clonesNumber }) => {
  const errors = validateSchema({ thingSchema, cloning: true })
  if(!errors.thingSchema) {
    const { base, created, modified } = JSON.parse(thingSchema)
    if (base !== undefined) errors.thingSchema = "'base' field is not allowed"
    if (created !== undefined) errors.thingSchema = "'created' field is not allowed"
    if (modified !== undefined ) errors.thingSchema = "'modified' field is not allowed"
    if(clonesNumber < 1 || clonesNumber > 100 ) errors.clonesNumber = "Number of clones must be between 1 and 100"
    if (Object.keys(errors) > 0) errors._error = { ...errors }
  }
  return errors
}


export const validateNumberResource = value => {
  if (value === "+" || value === "#") return
  else return numType(value)
}

export const isUlid = value => ulidRegex.test(value)

const ulidRegex = /[A-Z0-9]{26}/
export const validateUlid = value => {
  return !isUlid(value)? "Must be a ULID" : undefined
}

export const validateUlidResource = value => {
  if (value === "+" || value === "#") return
  else return validateUlid(value)
}

const propertyRegex = /[^\n\r\t/]+[\w\W]*/
export const validatePropertyName = value => {
  if (!value) return "Cannot be empty"
  return !propertyRegex.test(value)? "Invalid name" : undefined
}

export const validatePropertyNameResource = name => {
  if (name === "+" || name === "#") return
  else return validatePropertyName(name)
}

// TODO: refactor CreateCollectionForm to use this function
const nameRegex = /[^a-zA-Z0-9_:-]/
export const validateName = (name, { minChars=1, maxChars=26 }={}) => {
  if (!name) return "Cannot be empty"
  else if (name.length < minChars) return "Must include at least one character."
  else if (name.length > maxChars) return "Cannot exceed 26 characters"
  else if (name.includes(" ")) return "Must not include a space"
  else if (nameRegex.test(name)) return "Must not contain special characters"
}

export const validateNameResource = name => {
  if (name === "+" || name === "#") return
  else return validateName(name)
}

// TODO: refactor AddFunction pane to use this function
const maxFunctionNameLength = 32
const functionNameRegex = /^[a-z0-9-]+$/
export const validateFunctionName = functionName => {
  if (!functionName) return "Cannot be empty"
  else if (functionName.length > maxFunctionNameLength) return `Must be no more than ${maxFunctionNameLength} characters`
  else if (!functionNameRegex.test(functionName)) return "Must include only numbers (0-9), lowercase letters (a-z), and hyphens (-)."
}

export const validateFunctionNameResource = name => {
  if (name === "+" || name === "#") return
  else return validateFunctionName(name)
}

const usernameRegex = /^[a-zA-Z][a-zA-Z0-9]*$/
export const validateUsername = username => {
  if (!username) return "Cannot be empty"
  else if (username.length < 6) return "Must be at least 6 characters"
  else if (!usernameRegex.test(username)) {
    return "Must contain only letters and numbers, and cannot start with a number"
  }
}

export const validateUsernameResource = value => {
  if (value === "+" || value === "#") return
  else return validateUsername(value)
}

export const isEmpty = value => {
  if (!value) return "Cannot be empty"
}

export const validateEmptyResource = value => {
  if (value === "+" || value === "#") return
  else return isEmpty(value)
}

export const hasLeadingWhitespace = value => /^\s/.test(value)

export const hasSpecialChar = value => !/^[A-Za-z0-9_.-\s]+$/.test(value)

export const validateSchemaTitle = (value="", cloning=false, beta=false) => {
  const MAX_TITLE_LENGTH = beta ? 80 : 150

  if(cloning) value = value.replace(/{number}/, "0") // fix to allow number replacemente in clone schema
  if (value.length < 2) {
    return "Title must contain at least two characters."
  } else if (value.length > MAX_TITLE_LENGTH) {
    return `Title must be less than ${MAX_TITLE_LENGTH} characters.`
  } else if (hasSpecialChar(value)) {
    return "Title cannot contain special characters."
  } else if (hasLeadingWhitespace(value)) {
    return "Title cannot begin with a space."
  }
}

export const validateSchemaDescription = (value="") => {
  if (value.length > 1024) {
    return "Description must be less than 1024 characters."
  }
}

export const validateRolloutAction = (value="") => {
  const validActions = ["play", "pause", "resume", "+"]
  if (value === "+" || value === "#") return
  else if(!validActions.includes(value)) return "Valid actions are \"play\", \"pause\" or \"resume\"."
}

export const validateDistributionType = (value="") => {
  const validActions = ["ota_edge_apps", "ota_system"]
  if (value === "+" || value === "#") return
  else if (!validActions.includes(value)) return "Valid types are \"ota_edge_apps\", or \"ota_system\"."
}

export const validateVariableName = value => {
  if (value.length < 3) return "Name must have more than 2 characters"
  if (value.length > 40) return "Name cannot have more than 40 characters"
  else if (! /^[\w.-]+$/.test(value)) return 'Only alphanumeric characters, "_", "-" and "." are allowed'
}

export const validateVariableNameResource = (value="") => {
  if (value === "+" || value === "#") return
  else return validateVariableName(value)
}

export const validateSchemaCategoriesAndModel = async (
  thingSchemaString,
  categories,
  models,
  schema,
  oldModel,
  oldVersion,
) => {
  const { value: thingSchema, errors } = validateJSONSchema(thingSchemaString)

  if (errors?.thingSchema) {
    return errors.thingSchema
  }

  const {name, version} = thingSchema?.model || {}

  if (thingSchema?.categories?.length) {
    const invalidCategory = thingSchema?.categories?.find(category => !categories.find(({name}) => name === category))

    if (invalidCategory) {
      return `Category "${invalidCategory}" doesn't exist`
    }
  }

  if (!isEqual(thingSchema.categories, schema.categories)) {
    const thingCategories = thingSchema.categories || []
    const thingCategoriesWithModel = thingCategories.filter(categoryName => categories.find(({name, model}) => (categoryName === name) && model))

    if (thingCategoriesWithModel.length > 1) {
      return "You can only select one category with a model"
    }

    if (thingCategoriesWithModel.length === 1) {
      const category = thingCategoriesWithModel[0]
      const {model} = categories.find(({name}) => name === category)

      if (!name) {
        return `Model "${model.name}" is required for category "${category}"`
      }

      if (model?.name !== name) {
        return `Model "${name}" doesn't exist in category "${category}"`
      }

      if (model?.version !== version) {
        return `Model "${name}" doesn't have version "${version}" in category "${category}"`
      }
    }
  }

  if (thingSchema?.model) {
    if (name === schema?.model?.name && version === schema?.model?.version) {
      return
    }

    // Check if the model exists
    if (!models.find(m => m?.name === name)) {
      return `Model "${name}" doesn't exist`
    }

    if (version) {
      if (oldModel === name && oldVersion === version) {
        return
      }

      // Check if the model version exists
      const {data: versions} = await getModelVersionsRequest(name)

      if (!versions.find(v => v.version == version)) {
        return `Model "${name}" doesn't have version "${version}"`
      }
    }
  }
}

export const validateCronExpression = value => {
  const validText = ["@yearly", "@annually", "@monthly", "@weekly", "@daily", "@midnight", "@hourly"]
  // @every <interval>
  let every = false
  if (value.startsWith("@every")) {
    let values = value.split(" ")
    // h, m, s, ms, us, μs, ns
    every = /^(?:(?:-|)(?:\d+|\d+\.\d+)(?:µs|ns|us|ms|s|m|h))+$/.test(values[1])
  }
  let cronOptions = {
    preset: "default",
    override: {
      useSeconds: true,
      useYears: false,
      useAliases: true, // optional, default to false
      useBlankDay: true,
      allowOnlyOneBlankDayField: false,
    }
  }
  let cronOptionsNoSeconds = { ...cronOptions, override: {...cronOptions.override, useSeconds: false}}
  const cronResult = cron(value.trim(), cronOptions)
  const cronResultNoSeconds = cron(value.trim(), cronOptionsNoSeconds)
  return validText.includes(value) || cronResult.isValid() || cronResultNoSeconds.isValid() || every
}

export const validateNameValueArray = value => {
  if (value && value.length > 0) {
    const errors = []
    value.forEach((item, index) => {
      const itemErrors = {}
      if (!item || !item.name || item.name.trim().length === 0) {
        itemErrors.name = "Required"
        errors[index] = itemErrors
      }
      if (!item || !item.value) {
        itemErrors.value = "Required"
        errors[index] = itemErrors
      }
    })
    if (errors.length >0) return errors
  }
  return false
}

export const validateURL = urlString => {
  let url
  try {
    url = new URL(urlString)
  } catch (e) {return "Not a valid URL"}
  if (!["https:", "http:"].includes(url.protocol)) { return "Not a valid protocol"}
  return false
}