import {
  UnityMultiPane
} from "libs/UnityCoreReact"
import React, {useEffect, useReducer, useState} from "react"
import { connect } from "react-redux"
import {debounce} from "throttle-debounce"

import { MQTT_HOST } from "constants/config"
import { getCategories } from "reduxModules/categoriesBeta"
import { setMqttSubscriptions } from "reduxModules/mqttInspector"
import { getSpaceId } from "utils/auth"
import {
  getMessageContent,
  subscribe,
  unsubscribe
} from "utils/mqtt"
import {
  UPDATE_MQTT_MESSAGE_LIMIT,
  updateSelectedMqttTopic
} from "utils/utilityBelt"

import MessageVault from "./messageVault"
import SubscriptionModal from "./SubscriptionModal"
import TopicDetails from "./TopicDetails"
import TopicTree from "./TopicTree"

const TITLE = "Topics"
const DETAILS_TITLE = "Messages"
const TABLE = "topics-tree"
const DETAILS = "topic-details"

//Move to util
const getCategoryMap = (topicPath=[], categories=[]) => {
  return categories.reduce((categoryMap, category) => {
    const nextPath = [...topicPath, category.name]
    return {
      ...categoryMap,
      [category.name]: {
        topic: nextPath.join("/"),
        topicPath: nextPath,
        name: category.name,
        children: {}
      }
    }
  }, {})
}

const generateInitialTree = ({space, categories}) => {
  return {
    topic: MQTT_HOST,
    topicPath: [MQTT_HOST],
    name: MQTT_HOST,
    children: {
      spaces: {
        topic: "spaces",
        topicPath: ["spaces"],
        name: "spaces",
        children: {
          [space] : {
            topic: `spaces/${space}`,
            topicPath: ["spaces", space],
            name: space,
            children: {
              categories: {
                topic: `spaces/${space}/categories`,
                topicPath: ["spaces", space, "categories"],
                name: "categories",
                children: getCategoryMap(["spaces", space, "categories"], categories)
              }
            }
          }
        }
      },
    }
  }
}

const getInitialSubscriptions = () => {
  const space = getSpaceId()
  return [
    `spaces/${space}/#`
  ]
}

const stateToProps = ({
  categoriesBeta: {
    categories=[]
  }={},
  mqttInspector: {
    subscriptions=[]
  }={}
}) => ({
  categories,
  subscriptions
})

const MqttInspector = ({
  categories,
  subscriptions,
  getCategories,
  setMqttSubscriptions
}) => {
  // Even if set is never called, we might need to keep this on state
  const [space] = useState(() => {
    return {
      current: getSpaceId()
    }
  })

  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const [selectedTopic, setSelectedTopic] = useState()
  // Even if set is never called, we need this to be stored in state so that the a rerender is triggered when a message is added to the vault.
  const [messageVault] = useState(() => {
    return {
      current: new MessageVault({
        topicsTree: generateInitialTree({
          space: space.current,
          categories
        })
      })
    }
  })

  useEffect(() => {
    if (subscriptions.length === 0) {
      //set initial subscriptions
      setMqttSubscriptions(getInitialSubscriptions())
    }

    return function cleanup () {
      //Clear mqtt subscriptions
      setMqttSubscriptions(getInitialSubscriptions())
    }
  }, [])
  const debounceTimeout = 500


  useEffect(() => {
    getCategories()
    document.addEventListener(UPDATE_MQTT_MESSAGE_LIMIT, handleMessageLimitUpdate)

    return function cleanup() {
      document.removeEventListener(UPDATE_MQTT_MESSAGE_LIMIT, handleMessageLimitUpdate)
    }
  }, [])

  useEffect(() => {
    subscribe(subscriptions, messageHandler)
    handleSubscriptionUpdate()

    return function cleanup() {
      unsubscribe(subscriptions, messageHandler)
    }
  }, [subscriptions])

  const debouncedForceUpdate = debounce(debounceTimeout, false, forceUpdate)

  //NOTE: if defined as arrow function, its reference is not preserved so cannot unsubscribe
  function messageHandler (topic, message, meta) {
    const formattedMessage = getMessageContent(message)

    const formattedMeta = {
      dup: meta.dup,
      length: meta.length,
      qos: meta.qos,
      retain: meta.retain,
      timestamp: meta.timestamp || Date.now()
    }

    //No message filtering
    messageVault.current.add(topic, formattedMessage, formattedMeta)
    //Debounce component update so that if many messages come in simultaneously, they are bundled into one update
    debouncedForceUpdate()
  }


  const handleNodeClick = ({topic}) => {
    const nextSelectedTopic = topic === selectedTopic ? "" : topic

    setSelectedTopic(nextSelectedTopic)
    updateSelectedMqttTopic(nextSelectedTopic)
  }

  const handleSubscriptionUpdate = () => {
    messageVault.current.updateSubscriptions(subscriptions)
    debouncedForceUpdate()
  }

  const handleMessageLimitUpdate = ({detail: {limit=10}={}}) => {
    if (Number.isInteger(limit)) {
      messageVault.current.setMessageLimit(limit)
    }
  }


  const getVisiblePanes = () => {
    return selectedTopic
      ? [TABLE, DETAILS]
      : [TABLE]
  }
  const getPaneLabels = () => {
    return {
      [TABLE]: TITLE,
      [DETAILS]: DETAILS_TITLE
    }
  }

  const makeTableBody = () => {
    const tableData = messageVault.current.getTopicTableData()

    return <div style={styles.topicTree}>
      <TopicTree
        data={tableData}
        selectedTopic={selectedTopic}
        onNodeClick={handleNodeClick}
      />
    </div>
  }

  const makeDetailsBody = () => {
    // NOTE: cannot useMemo because do not know when message is added
    const messages = selectedTopic
      ? messageVault.current.getTopicMessages(selectedTopic)
      : []
    const formattedTopic = selectedTopic
      ? messageVault.current.getFormattedTopic(selectedTopic)
      : ""

    return <TopicDetails topic={formattedTopic} messages={messages} />
  }

  //NOTE: Cannot update panes after mount
  const makePanes = () => {
    const tablePane = {
      key: TABLE,
      body: makeTableBody()
    }
    const detailPane = {
      key: DETAILS,
      body: makeDetailsBody()
    }

    return [tablePane, detailPane]
  }

  return (
    <div style={styles.panel}>
      <UnityMultiPane
        style={styles.splitPane}
        visiblePanes={getVisiblePanes()}
        collapsedPanes={[]}
        labels={getPaneLabels()}
        panes={makePanes()}
      />

      <SubscriptionModal/>
    </div>
  )
}

export default connect(stateToProps, {
  getCategories,
  setMqttSubscriptions
})(MqttInspector)

const styles = {
  panel: {
    flex: 1,
    height: "100%"
  },
  splitPane: {
    "--border-color": "#636363",
    "--pane-z-index": 3
  },
  topicTree: {
    flex: 1,
    overflowX: "hidden",
    overflowY: "auto",
    minWidth: 0,
  },
  detailsPanel: {
    flex: 1,
    overflowX: "hidden",
    overflowY: "auto",
    minWidth: 0
  },
  cell: {
    display: "flex",
    flexDirection: "row",
    whitSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    minWidth: 0
  },
  topic: {
    "--font-weight": 600
  },
  topicDetails: {
    marginLeft: 6,
    "--font-size": "10px",
    // '--font-color'
  }
}