import React, { Component, HTMLAttributes, CSSProperties } from 'react'
// import '@bit/smartworks.unity.unity-core/unity-spinner'

export interface SpinnerProps extends HTMLAttributes<HTMLElement> {
  active?: boolean
  style?: CSSProperties & {
    '--spinner-color'?: string,
    '--spinner-stroke-width'?: string,
    '--spinner-size'?: string
  }
}

export default class UnitySpinner extends Component<SpinnerProps> {
  render() {
    const {
      active,
      style
    } = this.props

    let spinnerProps = {
      active,
      style
    }

    if (!spinnerProps.active) delete spinnerProps.active
    if (!spinnerProps.style) delete spinnerProps.style

    return (<unity-spinner
      {...spinnerProps}
    >
    </unity-spinner>)
  }
}
