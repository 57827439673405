import { UnityButton, UnityTypography } from "libs/UnityCoreReact"
import React, { useState } from "react"
import { createPortal } from "react-dom"
import { connect } from "react-redux"

import { unlinkAltairOne } from "actions/auth"
import Dialog from "components/common/Dialog"

const UserPopoverContentAltairOneStatusUnlinkButton = ({ unlinkAltairOne }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [saving, setSaving] = useState(false)
  const handleUnlinkAltairOne = async () => {
    setSaving(true)
    await unlinkAltairOne()
    setSaving(false)
    setShowDialog(false)
  }
  
  return (<>
    <UnityButton
      label={"Unlink AltairOne Licenses"}
      style={styles.linkAltairOneAccountButton}
      onClick={() => setShowDialog(true)}
      type="secondary"
      important
      id="unlink-altairone-account-button"
    />
    {createPortal(
      <Dialog
        show={showDialog}
        dialogTitle="Unlink AltairOne Licenses"
        body={
          <UnityTypography>
            Are you sure you want to unlink your AltairOne Licenses?
          </UnityTypography>
        }
        toggle={() => setShowDialog(!showDialog)}
        bottom={<>
          <UnityButton
            label="Cancel"
            id="unlink-altairone-modal-cancel-button"
            type="secondary"
            onClick={() => setShowDialog(false)}
          />
          <UnityButton
            label="Unlink AltairOne Licenses"
            id="unlink-altairone-modal-unlink-button"
            onClick={handleUnlinkAltairOne}
            style={{marginLeft: "10px"}}
            loading={saving}
            important
            disabled={saving}
          />
        </>}
      />, document.body
      
    )}
  </>
  )
}

const styles = {
  linkAltairOneAccountButton: {
    width: "fit-content",
    marginLeft: "auto",
    "--button-padding": "8px",
    "--unity-button-height": "24px",
  }
}

export default connect(null, {unlinkAltairOne})(UserPopoverContentAltairOneStatusUnlinkButton)